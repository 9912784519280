export const UserPoolClientId = '7cql2ciusi78td3haaop6q7il5';
export const UserPoolId = 'eu-west-1_RW45ITdi9';
export const IdentityPoolId = 'eu-west-1:012b5a83-0e07-4657-b1ba-fd35f4bd98e9';
export const GraphQLAPIURL = 'https://v45t75njgzhqriesfuyehkawvi.appsync-api.eu-west-1.amazonaws.com/graphql';
export const target = 'STAG';
export const iotDevBucketName = 'assetdeploy-dev-updatess3bucket-oz2ydono21ek';
export const iotStagBucketName = 'assetdeploy-stag-updatess3bucket-1m0j44dwx5cpl';

const config = {
  aws_project_region: 'eu-west-1',
  aws_user_pools_id: UserPoolId,
  aws_user_pools_web_client_id: UserPoolClientId,
  aws_appsync_graphqlEndpoint: GraphQLAPIURL,
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  Auth: {
    identityPoolId: IdentityPoolId,
    region: 'eu-west-1',
    userPoolId: UserPoolId,
    userPoolWebClientId: UserPoolClientId,
  },
  Storage: {
    AWSS3: {
      bucket: `alm-graphql-files-bucket-${target.toLowerCase()}`,
      region: 'eu-west-1',
    },
  },
};

export default config;
